<template>
  <Teleport v-if="teleportElement" :to="teleportElement">
    <form class="modal" :class="this.width" @submit="submit">
      <div class="header">
        <div class="text-container">
          <template v-if="texts.headerPrimary || texts.headerSecondary">
            <span class="primary-title">{{ $t(texts.headerPrimary) }}</span>
            <span class="secondary-title">{{ texts.headerSecondary }}</span>
          </template>
          <slot v-else name="header" />
        </div>
        <span class="close-btn" v-if="showCloseCorner" @click="close">X</span>
      </div>
      <div class="seperator" />
      <div class="main-content">
        <slot></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
        <div>
          <Button :isClean="true" @click="close" v-if="showCancelBtn">{{ texts.footerCancel || $t('cancel') }} </Button>
          <Button :isClean="true" @click="submit" v-if="showConfirmBtn" class="confirm" skin="primary" :disabled="isConfirmDisabled">{{ texts.footerConfirm || $t('OK') }} </Button>
        </div>
      </div>
      <button type="submit" hidden />
    </form>
  </Teleport>
</template>

<script>
// Needs to be rendered outside tabs from material
import Button from './Button'
import Teleport from 'vue2-teleport'

export default {
  props: {
    width: {
      type: String,
      validator: (prop) => prop === 'small' || prop === 'medium' || prop === 'large',
      required: true,
    },
    showScreen: {
      type: Boolean,
      default: true,
    },
    texts: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showCancelBtn: {
      type: Boolean,
      default: true,
    },
    showConfirmBtn: {
      type: Boolean,
      default: true,
    },
    showCloseCorner: {
      type: Boolean,
      default: true,
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      teleportElement: null,
    }
  },
  created() {
    if (this.showScreen) {
      this.$store.dispatch({ type: 'setScreenMode', isVisible: true })
    }
  },
  mounted() {
    this.teleportElement = this.parent === 'shift' ? '.main' : `.${this.$el.parentElement.className}`
  },
  destroyed() {
    this.$store.dispatch({ type: 'setScreenMode', isVisible: false })
  },
  methods: {
    submit(ev) {
      ev.preventDefault()
      this.$emit('submit')
    },
    close() {
      this.$emit('close')
      this.$store.dispatch({ type: 'setScreenMode', isVisible: false })
    },
  },
  components: {
    Button,
    Teleport,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.modal {
  // text-transform: capitalize;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  background-color: $white;
  padding-bottom: 50px;
  border-radius: 10px;

  .seperator {
    border-bottom: 1px solid #eaeaea;
  }

  &.small {
    min-width: 18vw;
    max-width: 50vw;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &.medium {
    min-width: 32vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.large {
    min-width: 60vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .header {
    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: space-between;

    .text-container {
      & > span {
        margin-inline-start: 40px;
      }

      .primary-title {
        font: Bold 24px/28px Ubuntu;
        letter-spacing: -0.27px;
        color: $black-blue;
      }

      .secondary-title {
        font: Normal 24px/28px Ubuntu;
        letter-spacing: -0.27px;
        color: $black-blue;
        margin-inline-start: 8px;
      }
    }

    .close-btn {
      cursor: pointer;
      margin-inline-end: 20px;
      transform: scaleX(1.7) scaleY(1);
      color: #95989a;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-inline-start: 40px;
    margin-top: 44.5px;
    margin-bottom: 40px;
    margin-inline-end: 50px;
  }

  .footer {
    display: flex;
    padding-inline-start: 40px;

    > div {
      margin: auto;
    }

    .confirm {
      margin-inline-start: 40px;
    }
  }
}
</style>
