<template>
  <div 
    :class="{ 'table-row': true, active: nestedTableOpen, clickable: hasNested && showArrow }" 
    @click="emitRowClick"
    @mouseover="setShowActions(true); showRowIndex = true"
    @mouseout="setShowActions(false); showRowIndex = false"
    :style="{ display: 'grid', 'grid-template-columns': this.gridString }">
    <div class="checkbox-container" v-if="isSelect" @click.stop>
      <Checkbox v-model="checkboxCurrValue" />
    </div>
    <div class="triangle-container" v-if="hasNested && showArrow" @click.stop="nestedTableClick">
      <img :class="{ active: nestedTableOpen }" :src="require('@/assets/Triangle.svg')" alt="triangle" />
    </div>
    <!-- <div v-if="showRowIndex && !isHidingRowIndex" class="row-index">
      {{ isHidingRowIndex ? '' : rowIndex + 1 + '#'}}
    </div> -->
    <TableCell
      class="table-cell"
      v-for="(item, index) in values"
      :key="index"
      :item="item"
      :isHeader="isHeader"
      :showActions="showActions"
      :isIgnored="isIgnored"
      @cellClick="emitClick"
      @checkboxClick="handleActionClick"
      @setActionFlags="setActionFlags"
      @toggleFlag="toggleFlag"
      :style="{
        gridColumnStart: index + addToRowStart,
        width: columnWidth ? columnWidth : '100%',
      }"
    />
    <div :class="['cell-icon', 'cell-button-container']" v-if="buttonText">
      <button class="cell-button" @click.stop="buttonClicked" v-if="!isHeader">{{ buttonText }}</button>
    </div>
    <template v-if="!isHeader && extraActions.length">
      <div class="cell-icon action" v-for="(extraAction, idx) in extraActions" :key="extraAction.name + idx" @click.stop="extraAction.type !== 'survey-template' ? handleActionClick(extraAction) : ''">
        <img v-if="extraAction.type !== 'survey-template'" :src="require(`@/assets/${extraAction.name}.svg`)" :alt="extraAction.name" />
      </div>
    </template>
    <div class="cell-icon action" v-if="editOptions && editOptions.edit && !isHeader" @click.stop="editRow">
      <img :src="require('@/assets/edit-icon.svg')" alt="edit-icon" />
    </div>
    <div class="cell-icon action" v-if="editOptions && editOptions.remove && !isHeader" @click.stop="removeRow">
      <img :src="require('@/assets/delete-icon.svg')" alt="remove-icon" />
    </div>
    <div class="table-cell" v-if="!isHeader && !isHidingRowIndex">
      {{ rowIndex + 1 }}
    </div>
  </div>
</template>

<script>
import Checkbox from '../Checkbox'
import TableCell from './TableCell'

export default {
  props: {
    rowIndex: { type: Number, required: false },
    isSelect: { type: Boolean, default: false },
    isHeader: { type: Boolean, default: false },
    hasNested: { type: Boolean, default: false },
    values: { type: Array, required: true },
    checkboxValue: { type: Boolean, required: false },
    showArrow: { type: Boolean, default: false },
    editOptions: { type: Object, required: false },
    columnWidth: { type: String, required: false },
    buttonText: { type: String, required: false },
    isHidingId: { type: Boolean, default: true },
    customRowGridStr: { type: String, required: false },
    extraActions: { type: Array, required: false, default: () => [] },
    nestedTableOpen: { type: Boolean, default: false },
    isIgnored: { type: Boolean, required: false },
    isHidingRowIndex: { type: Boolean, default: true },
  },
  data() {
    return {
      checkboxCurrValue: this.checkboxValue,
      showActions: false,
      showRowIndex: false,
    }
  },
  watch: {
    checkboxCurrValue(value) {
      this.$emit('checkboxClick', value, this.rowIndex)
    },
    checkboxValue: {
      handler(value) {
        this.updateCheckBox(value)
      },
      immediate: true,
    },
  },
  methods: {
    handleActionClick(extraAction) {
      this.$emit('action', extraAction.type)
    },
    emitRowClick() {
      this.nestedTableClick()
      this.$emit('rowClick', this.rowIndex)
    },
    editRow(ev) {
      this.$emit('editRow', this.rowIndex)
    },
    removeRow(ev) {
      this.$emit('removeRow', this.rowIndex)
    },
    emitClick(value, isActive) {
      this.$emit('cellClick', value, isActive)
    },
    buttonClicked() {
      this.$emit('buttonClick', this.rowIndex)
    },
    updateCheckBox(value) {
      this.checkboxCurrValue = value
    },
    nestedTableClick() {
      if (!this.hasNested || !this.showArrow) return
      this.$emit('openNested', this.rowIndex, this.nestedTableOpen)
    },
    setShowActions(value) {
      this.showActions = value
    },
    setActionFlags(actionFlags) {
      this.$emit('setActionFlags', actionFlags)
    },
    toggleFlag(flag, actionFlags) {
      this.$emit('toggleFlag', flag, actionFlags)
    },
  },
  computed: {
    gridString() {
      if (this.customRowGridStr) return this.customRowGridStr
      let str = ''
      //space after the additional string is IMPORTANT or else it wont work!
      if (this.isSelect) str += '45px '
      if (this.hasNested) str += '30px '
      if (!this.isHidingId) {
        str += '50px '
      }
      let fieldCount = this.isHidingId ? this.columnCount : this.columnCount - 1
      for (let i = 0; i < fieldCount; i++) {
        str += '1fr '
      }
      if (this.buttonText) str += '150px '
      for (let i = 0; i < this.extraActions.length; i++) {
        str += '50px '
      }
      if (this.editOptions && this.editOptions.remove) str += '50px '
      if (this.editOptions && this.editOptions.edit) str += '50px '
      return str
    },
    addToRowStart() {
      let add = 1
      if (this.isSelect) add++
      if (this.hasNested) add++
      return add
    },
    columnCount() {
      return this.values.length
    },
  },
  components: {
    Checkbox,
    TableCell,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$border-color: #f3f3f3;

.table-row {
  height: 55px;
  border-bottom: 1px solid $border-color;
  align-items: center;
  &.clickable {
    cursor: pointer;
  }

  &:hover {
    .action,
    .cell-button {
      visibility: unset;
    }
    .row-index {
      visibility: unset;
    }
  }
  .row-index {
    visibility: hidden;
  }

  &:hover,
  &.active {
    background-color: #f5fafe;
  }
  padding: 0 15px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.triangle-container {
  align-self: center;
  img {
    transform: rotate(-90deg);
    transition: transform 0.2s;
    cursor: pointer;
    &.active {
      transform: unset;
      transition: transform 0.2s;
    }
  }
}

.cell-button-container {
  justify-content: center;
  .cell-button {
    background-color: rgba(56, 156, 235, 1);
    color: white;
    height: 24px;
    width: 100px;
    border-radius: 12px;
    text-transform: uppercase;
    cursor: pointer;
    visibility: hidden;
    &:hover {
      background-color: rgba(56, 156, 235, 0.8);
    }
  }
}

.cell-icon {
  @include flex-center;
  &:hover {
    cursor: pointer;
  }
  &.action {
    max-width: 40%;
    height: 100%;
    margin: 0 auto;
    visibility: hidden;
  }
}

.checkbox-container {
  padding-bottom: 22px;
  @include rtl {
    padding-right: 25px;
  }
}
</style>
