<template>
  <section class="table-container">
    <div :class="['table-head']">
      <TableRow
        :values="headers"
        @checkboxClick="handleHeaderClick"
        :isSelect="isSelect"
        :hasNested="isNested"
        style="background-color:unset;"
        @cellClick="handleSortClick"
        :isIgnored="isPlaceholder"
        :isHidingId="isHidingId"
        :isHeader="true"
        :editOptions="editOptions"
        :style="{ 'font-size': headerFontSize }"
        :columnWidth="columnWidth"
        :buttonText="buttonText"
        :customRowGridStr="customRowGridStr"
        :extraActions="extraActions.map(() => {})"
      />
    </div>
    <div class="table-body" v-if="tableDataCopy">
      <template v-for="(item, index) in tableDataCopy">
        <TableRow
          v-if="!rowData(item, index).includes('placeholder')"
          :key="tableRowKey(item,index)"
          :showArrow="tableData[index] && !!tableData[index].nestedTable"
          :values="rowData(item, index)"
          :rowIndex="index"
          :isHidingRowIndex="isHidingRowIndex"
          :checkboxValue="selectAll"
          @checkboxClick="checkboxHandler"
          :isSelect="isSelect"
          :hasNested="isNested"
          @openNested="openNested"
          :isHidingId="isHidingId"
          :editOptions="editOptions"
          @editRow="emitRowToEdit"
          @removeRow="emitRowToRemove"
          @rowClick="rowClick"
          :style="{ 'font-size': bodyFontSize }"
          :columnWidth="columnWidth"
          :buttonText="buttonText"
          @buttonClick="buttonClicked"
          @updateTable="updateTable"
          @setActionFlags="setActionFlags"
          @toggleFlag="toggleFlag"
          :extraActions="extraActions"
          :customRowGridStr="customRowGridStr"
          :nestedTableOpen="activeNestedIndexes.includes(index)"
          @cellClick="clickedCell"
          @action="
            (type) => {
              handleActionClick({ type, idx: index })
            }
          "
        />
        <Table :key="index + 100" v-if="activeNestedIndexes.includes(index)" :tableData="tableData[index].nestedTable" :buttonText="nestedButtonText" :idField="nestedIdField" @removeRow="removeNestedRow($event, index)" :editOptions="{ edit: isAdmin && isNestedEditable, remove: isAdmin }" @buttonClick="nestedButtonClicked($event, index)" />
      </template>
    </div>
  </section>
</template>

<script>
import TableRow from './TableRow'

export default {
  name: 'Table',
  props: {
    tableData: { type: Array, required: true },
    idField: { type: String, required: false },
    nestedIdField: { type: String, required: false },
    headerFontSize: { type: String, required: false },
    bodyFontSize: { type: String, required: false },
    columnWidth: { type: String, required: false },
    buttonText: { type: String, required: false },
    nestedButtonText: { type: String, required: false },
    isSelect: { type: Boolean, default: false },
    isNested: { type: Boolean, default: false },
    isNestedEditable: { type: Boolean, default: true },
    isHidingId: { type: Boolean, default: true },
    isAdmin: { type: Boolean, default: false },
    customRowGridStr: { type: String, required: false },
    initialOpenNested: { type: Array, default: () => [] },
    editOptions: {
      type: Object,
      default: () => ({ edit: false, remove: false }),
    },
    extraActions: { type: Array, default: () => [] },
    isHidingRowIndex: { type: Boolean, default: true },
  },
  data() {
    return {
      headers: [],
      tableDataCopy: [],
      haveNestedTable: [],
      selectedItemsIndexes: [],
      activeNestedIndexes: [],
      selectAll: false,
    }
  },
  created() {
    if (this.initialOpenNested) this.activeNestedIndexes = this.initialOpenNested
    this.updateTable()
    if (!this.tableData[0]) return
    const tableItem = JSON.parse(JSON.stringify(this.tableData[0]))
    const tableKeys = Object.keys(tableItem)
    if (this.idField) {
      delete tableItem[this.idField]
    }
    this.handleSortClick(tableKeys[0], true)
  },
  methods: {
    handleActionClick(actionType) {
      this.$emit('action', actionType)
    },
    tableRowKey(item,index) {
      return item?.device_id || item?.id ? item?.device_id || item?.id : index + 100 + index * 2
    },
    rowClick(index) {
      this.$emit('rowClick', this.tableData[index][this.idField])
    },
    clickedCell(payload) {
      this.$emit('cellClicked', payload)
    },
    emitRowToEdit(index) {
      this.$emit('editRow', this.tableData[index])
    },
    emitRowToRemove(index) {
      this.$emit('removeRow', this.tableData[index][this.idField])
    },
    buttonClicked(index) {
      this.$emit('buttonClick', index)
    },
    nestedButtonClicked(nestedIndex, roomIndex) {
      this.$emit('nestedButtonClick', nestedIndex, roomIndex)
    },
    removeNestedRow(id, index) {
      this.$emit('deleteNestedItem', id, this.tableData[index][this.idField])
    },
    handleSortClick(value, isActive) {
      value = value.split(' ').join('_')
      if (isActive) {
        const itemsToSort = ['tableData', 'tableDataCopy']
        itemsToSort.forEach((keyToSort) => {
          this[keyToSort].sort((a, b) => {
            const aValue = a[value]
            const bValue = b[value]
            if ((aValue && bValue) || (aValue === 0 && bValue) || (aValue && bValue === 0) || (aValue === 0 && bValue === 0)) {
              if (typeof aValue === 'number') {
                return a[value] - b[value]
              } else if (typeof a[value] === 'string') {
                return aValue.localeCompare(bValue)
              } else if (typeof aValue === 'object' && aValue.date) {
                return new Date(aValue.date) - new Date(bValue.date)
              } else if (typeof a[value] === 'object' && aValue.icon) {
                if (value === 'counter_status' || value === 'status') {
                  return aValue.icon.localeCompare(bValue.icon)
                } else {
                  return bValue.icon.localeCompare(aValue.icon)
                }
              }
            }
          })
        })
      } else {
        const tableItem = JSON.parse(JSON.stringify(this.tableData[0]))
        const tableKeys = Object.keys(tableItem)
        if (this.idField) {
          delete tableItem[this.idField]
        }
        this.handleSortClick(tableKeys[0], true)
      }
    },
    openNested(index, isOpen) {
      if (!isOpen) this.activeNestedIndexes.push(index)
      else {
        this.activeNestedIndexes = this.activeNestedIndexes.filter((item) => item !== index)
      }
      this.$emit('updatedNestedOpen', JSON.parse(JSON.stringify(this.activeNestedIndexes)))
    },
    checkboxHandler(value, index) {
      if (value) {
        this.selectedItemsIndexes.push(index)
      } else {
        const indexToRemove = this.selectedItemsIndexes.indexOf(index)
        this.selectedItemsIndexes.splice(indexToRemove, 1)
      }
      this.$emit('select', this.selectedItems)
    },
    rowData(item, index) {
      if (item.nestedTable) {
        this.haveNestedTable.push(index)
        delete item.nestedTable
      }
      if (this.idField && this.isHidingId) delete item[this.idField]
      return Object.values(item)
    },
    updateTable() {
      if (!this.table && !this.tableData[0]) return
      let itemForValues = {}
      if (this.tableData[0].nestedTable || this.idField) {
        itemForValues = { ...this.tableData[0] }
        delete itemForValues.nestedTable
        if (this.isHidingId) delete itemForValues[this.idField] // removing id Field
      } else itemForValues = this.tableData[0]
      const headers = Object.keys(itemForValues).map((k) => (k === 'is_template' ? '' : k))
      this.tableDataCopy = JSON.parse(JSON.stringify(this.tableData))
      if (!this.isHidingRowIndex) {
        headers.push('#')
      }
      this.headers = headers
    },
    handleHeaderClick(value) {
      this.selectAll = value
    },
    setActionFlags(actionFlags) {
      this.$emit('setActionFlags', actionFlags)
    },
    toggleFlag(flag, actionFlags) {
      this.$emit('toggleFlag', flag, actionFlags)
    },
  },
  watch: {
    tableData() {
      this.updateTable()
    },
  },
  computed: {
    columnCount() {
      return this.headers.length
    },
    selectedItems() {
      return this.selectedItemsIndexes.map((index) => this.tableData[index])
    },
    isPlaceholder() {
      const parent = this?.$parent?.$el?.className
      return this.tableData?.[0]?.[parent === 'shift-table' ? 'shift name' : 'name']?.includes('placeholder') ? true : false
    },
  },
  components: {
    TableRow,
    Table: this,
  },
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';

$head-color: #909090;

.table-container {
  background-color: #fff;
	border-collapse: collapse;
	margin-bottom: 20px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  margin-inline-start: 22px;
  padding: 0 10px;
}

.table-head {
  text-transform: uppercase;
  color: $head-color;
  font-size: $font-size-smaller;
}

.table-body {
  font-size: $font-size-small;
}

.sticky-table-head {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
}
</style>
